<template>
  <div>
    <div class="d-flex align-items-center">
      <h1 class="h3">
        {{ $t('productAvailability.goodsReceipt') }}
      </h1>
      <div class="ml-auto">
        <a-button
          v-if="params.warehouse_id && permission.includes('WRITE') && !flagWms"
          type="primary"
          size="large"
          @click="openModal(true)"
        >
          <a-icon type="plus-square" />
          Buat GR
        </a-button>
      </div>
    </div>
    <a-row :gutter="24" class="mt-4">
      <a-col :span="9">
        <a-select
          v-model="params.warehouse_id"
          :placeholder="$t('productAvailability.gudang')"
          class="select-antd-default"
          size="large"
          :allow-clear="warehouseList.length > 1"
          :disabled="loading || isLoading"
          @change="handleChangeWarehouse"
        >
          <a-select-option
            v-for="item in warehouseList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
        <a-alert
          v-if="!params.warehouse_id"
          class="mt-2"
          style="font-size: 13px"
          :message="$t('productAvailability.warningGr')"
          type="warning"
          show-icon
        />
      </a-col>
      <a-col :span="9">
        <a-input-search
          v-model="params.query"
          size="large"
          placeholder="Cari"
          :loading="loading || isLoading"
          :disabled="loading || isLoading"
          allow-clear
          @search="handleSearch"
        >
          <a-button slot="enterButton" type="danger" icon="search">
            {{ $t('utils.search') }}
          </a-button>
        </a-input-search>
      </a-col>
    </a-row>
    <div class="mt-4">
      <a-table
        class="gr-list"
        :data-source="products"
        :row-key="record => record.id"
        :columns="columns"
        :loading="loading || isLoading"
        :pagination="false"
        :scroll="scroll"
      >
        <template slot="number" slot-scope="text">
          <a-tooltip placement="topRight">
            <template #title>
              <span>{{ text }}</span>
            </template>
            {{ text }}
          </a-tooltip>
        </template>
        <template slot="date" slot-scope="text">
          {{ $moment(text).format('HH:mm:ss, DD MMM YYYY') }}
        </template>
      </a-table>
    </div>
    <div class="mt-4 text-right">
      <a-pagination
        v-model="params.page"
        :page-size="params.size"
        :page-size-options="sizeOptions"
        :total="total_row"
        :show-total="(total, range) => $t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total })"
        :disabled="loading || isLoading"
        show-size-changer
        @change="handlePageChange"
        @showSizeChange="handleShowSizeChange"
      />
    </div>

    <ModalCreateGR
      :show-modal="visibleModal"
      :loading="loading || isLoading"
      :warehouse="params.warehouse_id"
      @handleModal="openModal"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { getGoodsReceipt } from '@/api/good-receive'
import { columnsGoodReceipt } from '@/data/columns'

export default {
  components: {
    ModalCreateGR: () => import(/* webpackPrefetch: true */ './component/ModalCreateGR.vue'),
  },
  data() {
    return {
      screenHeight: ((screen.height - 900) * 0.5) + (screen.height * 0.5),
      columns: columnsGoodReceipt,
      total_row: 0,
      loading: false,
      isLoading: false,
      visibleModal: false,
      flagWms: false,
      businessList: [],
      warehouseList: [],
      products: [],
      sizeOptions: ['10', '20', '30', '40', '50'],
      params: {
        warehouse_id: undefined,
        query: '',
        page: 1,
        size: 10,
      },
    }
  },
  computed: {
    businessId() {
      return (this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id) || ''
    },
    scroll() {
      return {
        y: this.screenHeight > 450
          ? this.screenHeight
          : 360,
        x: 1000,
      }
    },
    tHeight() {
      return !this.products.length ? '0px'
        : this.screenHeight > 450 ? `${this.screenHeight}px`
        : '450px'
    },
    permission() {
      return this.$store.getters['user/can']('goods-receipt')
    },
  },
  watch: {
    businessId() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    openModal(param) {
      this.visibleModal = param
    },
    init: debounce(async function() {
      this.loading = true
      await Promise.all([
        this.getBusinessList(),
        this.getWarehouseList(),
        this.getInventoryList(),
      ])
        .then(response => {
          this.businessList = response[0]
          this.warehouseList = response[1]
          this.products = response[2]
          const filter = this.warehouseList.filter(v => {return v.value === this.params.warehouse_id})
          this.flagWms = filter?.[0]?.wms_code === null || filter?.[0]?.wms_code?.toUpperCase() === 'N/A' || filter?.[0]?.wms_code === '' ? false : true
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => this.loading = false)
    }, 500),
    async getWarehouseList() {
      return await this.$store
        .dispatch('warehouse/GETWAREHOUSELIST', {
          business_id: this.businessId,
        })
        .then(res => {
          let response = this.$store.state.user.restriction_base === 'Warehouse'
            ? res.filter(warehouse => warehouse.id == this.$route.query.warehouse_id)
            : res
          this.params.warehouse_id = this.$store.state.user.restriction_base === 'Warehouse'
            ? this.$route.query.warehouse_id
            : this.$store.state.user.restriction_base !== 'Warehouse' && res.length === 1
            ? res[0].value
            : undefined
          return response
        })
        .catch(err => {
          this.params.warehouse_id = undefined
          console.error(err)
          return []
        })
    },
    async getInventoryList() {
      this.isLoading = true
      return await getGoodsReceipt({
          ...this.params,
          business_id: this.businessId,
        })
        .then(({ data: response }) => {
          this.total_row = response?.total_items || 0
          return response?.data || []
        })
        .catch(err => {
          this.total_row = 0
          console.error(err)
          return []
        })
        .finally(() => this.isLoading = false)
    },
    async getBusinessList() {
      return await this.$store
        .dispatch('business/GETBUSINESS', {
          business_id: this.businessId,
        })
        .then(({ data }) => data)
        .catch(err => {
          console.error(err)
          return []
        })
    },
    async handleChangeWarehouse(value) {
      if(value) {
        const filter = this.warehouseList.filter(warehouse => { return warehouse.value === value })
        this.flagWms = filter?.[0]?.wms_code === null
          || filter?.[0]?.wms_code?.toUpperCase() === 'N/A'
          || filter?.[0]?.wms_code === ''
            ? false
            : true
      }
      this.params.page = 1
      this.products = await this.getInventoryList()
    },
    async handleSearch() {
      this.params.page = 1
      this.products = await this.getInventoryList()
    },
    async handlePageChange(pageNumber) {
      this.params.page = pageNumber
      this.products = await this.getInventoryList()
    },
    async handleShowSizeChange(current, pageSize) {
      this.params.page = current
      this.params.size = pageSize
      this.products = await this.getInventoryList()
    },
  },
}
</script>

<style lang="scss">
.select-antd-default.ant-select-lg {
  width: 100%;

  .ant-select-selection__rendered {
    line-height: 38px !important;
  }
}

.gr-list div.ant-table-body {
  min-height: v-bind(tHeight);
}
</style>